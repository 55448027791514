import { Box, Sheet, Typography } from "@mui/joy"
import MainTemplate from "../../../templates/main"
import { Link } from "react-router-dom"

const NotFound = () => {
  return (
    <MainTemplate page="Verify" sender={false}>
      <Box
        sx={{
          display: 'flex',
          mt: 2,
          gap: 4,
          flexDirection: { xs: 'column' },
          alignItems: { xs: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <Typography level="h2" component="h1">
          Ooops! Page not found
        </Typography>
      <Sheet
        className="404Container"
        variant="outlined"
        sx={{
          display: { xs: 'flex' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          justifyContent: 'center'
        }}
      >
        <img src="/broken.png" alt="broken-box" style={{ maxWidth: '100%' }} />
      </Sheet>
      <Box
        sx={{
          display: 'flex',
          mt: 1,
          gap: 1,
          flexDirection: { xs: 'column' },
          alignItems: { xs: 'center' },
          flexWrap: 'wrap',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        {/* <Link to={ROUTES.dashboard}>Return to Sender View</Link> */}
        <Link to="/recipient/order-status/INV-1250">Return to Recipient View</Link>
      </Box>
      </Box>
    </MainTemplate>
  )
}

export default NotFound