import { Box, Button, Card, CardActions, CardOverflow, Divider, FormControl, FormHelperText, FormLabel, Input, Option, Select, Stack, Typography } from "@mui/joy"
import { useCallback, useState } from "react"
import MainTemplate from "../../../templates/main"
import { useNavigate } from "react-router-dom"
import { InfoOutlined } from "@mui/icons-material"

const CreateOrder = ({ sender }: { sender: boolean }) => {
  const navigate = useNavigate()
  const [phoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [verificationType, setVerificationType] = useState('OTP');
  const [delayTimeUnit, setDelayTimeUnit] = useState('m');


  const handleSubmit = useCallback((e: any) => {
    e.preventDefault();

    const elements = e.target.elements

    const phone = elements['recipient_phone']?.value
    const name = elements['recipient_name']?.value

    const regex = /\d\d\d\d\d\d\d\d\d\d/

    if (!regex.test(phone)) {
      setPhoneError(true)
      return
    }

    if (name.split(' ').length < 2) {
      setNameError(true);
      return;
    }

    const delay_time_value = elements['delay_time']?.value;

    let delay_time = 0;

    if (delay_time_value) {
      if (delayTimeUnit === 'd' || delayTimeUnit === 'h') delay_time = 300;
      if (delayTimeUnit === 'm') delay_time = Math.min(delay_time_value * 60, 300);
    }

    const payload = {
      "invoice_number": elements['invoice_number']?.value,
      "shipment": {
        "to_address": {
          "name": name,
          "verification_type": verificationType,
          "last_4_digits": elements['last_4_digits']?.value,
          "delay_time": delay_time,
          "street1": elements['recipient_address_1']?.value,
          "street2": elements['recipient_address_2']?.value,
          "city": elements['recipient_city']?.value,
          "state": elements['recipient_state']?.value,
          "zip": elements['recipient_zip']?.value,
          "country": "US",
          "phone": phone,
          "email": elements['recipient_email']?.value
        },
        "from_address": {
          "name": elements['sender_name']?.value,
          "street1": elements['sender_address_1']?.value,
          "street2": elements['sender_address_2']?.value,
          "city": elements['sender_city']?.value,
          "state": elements['sender_state']?.value,
          "zip": elements['sender_zip']?.value,
          "country": "US",
          "phone": elements['sender_phone']?.value,
          "email": elements['sender_email']?.value
        },
        "parcel": {
          "length": elements['p_length']?.value,
          "width": elements['width']?.value,
          "height": elements['height']?.value,
          "weight": elements['weight']?.value
        }
      }
    }

    fetch('https://hook.us1.make.com/pz6jnqizkgdj4xfd54ygq4523bjoc85h', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    }).then(results => results.json())
      .then(data => navigate(`/sender/print-qr-code/${data.inv}?verification_type=${verificationType}`));

  }, [navigate, verificationType, delayTimeUnit]);

  return (
    <MainTemplate page="Create Order" activeSidebar='orders' sender={true}>
      <>
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="h2" component="h1">
            Create Order
          </Typography>
          {/* <Button
            color="primary"
            startDecorator={<DownloadRoundedIcon />}
            size="sm"
          >
            Download PDF
          </Button> */}
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack
            spacing={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '1200px',
              // mx: 'auto',
              px: { xs: 2, md: 6 },
              py: { xs: 2, md: 3 },
            }}
          >
            <Card>
              <Box sx={{ mb: 1 }}>
                <Typography level="title-md">Sender Info</Typography>
              </Box>
              <Divider />
              <Stack
                direction="row"
                spacing={3}
                sx={{ display: { xs: 'flex' }, my: 1 }}
              >
                <Stack spacing={2} sx={{ flexGrow: 1, maxWidth: '100%' }}>
                  <Stack spacing={2}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Name</FormLabel>
                      <Input name="sender_name" size="sm" placeholder="Name" required={true} defaultValue="Corporate Headquarters" />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Email</FormLabel>
                      <Input
                        size="sm"
                        type="email"
                        name="sender_email"
                        // startDecorator={<EmailRoundedIcon />}
                        placeholder="Optional Email"
                        sx={{ flexGrow: 1 }}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Phone</FormLabel>
                      <Input name="sender_phone" size="sm" placeholder="Optional Phone" />
                    </FormControl>
                  </Stack>
                  <Stack spacing={2}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Address Line 1</FormLabel>
                      <Input name="sender_address_1" size="sm" placeholder="Address" required={true} defaultValue="123 Apple St" />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Address Line 2</FormLabel>
                      <Input
                        size="sm"
                        name="sender_address_2"
                        // startDecorator={<EmailRoundedIcon />}
                        placeholder="Address Line 2"
                        sx={{ flexGrow: 1 }}
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>City</FormLabel>
                      <Input name="sender_city" size="sm" placeholder="City" required={true} defaultValue="Chicago" />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>State</FormLabel>
                      <Input
                        size="sm"
                        name="sender_state"
                        // startDecorator={<EmailRoundedIcon />}
                        placeholder="State"
                        sx={{ flexGrow: 1 }}
                        required={true}
                        defaultValue="IL"
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Zip Code</FormLabel>
                      <Input
                        size="sm"
                        name="sender_zip"
                        // startDecorator={<EmailRoundedIcon />}
                        placeholder="Zip Code"
                        sx={{ flexGrow: 1 }}
                        required={true}
                        defaultValue="66667"
                      />
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>

            </Card>

            <Card>
              <Box sx={{ mb: 1 }}>
                <Typography level="title-md">Recipient Info</Typography>
              </Box>
              <Divider />
              <Stack
                direction="row"
                spacing={3}
                sx={{ display: { xs: 'flex' }, my: 1 }}
              >
                <Stack spacing={2} sx={{ flexGrow: 1, maxWidth: '100%' }}>
                  <Stack spacing={2}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Name</FormLabel>
                      <Input name="recipient_name" size="sm" placeholder="Name" />
                      {nameError && <FormHelperText>
                        <InfoOutlined />
                        Must include first and last name
                      </FormHelperText>}
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Email</FormLabel>
                      <Input
                        size="sm"
                        type="email"
                        name="recipient_email"
                        // startDecorator={<EmailRoundedIcon />}
                        placeholder="Email"
                        sx={{ flexGrow: 1 }}
                        required={true}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Verification type</FormLabel>
                      <Select defaultValue="OTP" onChange={(e: any, newValue: any) => {
                        setVerificationType(newValue)
                      }}>
                        <Option value="OTP">OTP</Option>
                        <Option value="CC">Last 4 Digits of CC</Option>
                        <Option value="PI">Photo Identification</Option>
                      </Select>
                    </FormControl>
                    {verificationType === 'CC' && <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Last 4 Digits of CC</FormLabel>
                      <Input name="last_4_digits" size="sm" placeholder="1234" />
                    </FormControl>}
                    <FormControl>
                      <FormLabel>Verification Delay on Label Print</FormLabel>
                      <Input name="delay_time" size="sm" placeholder="30" endDecorator={
                        <>
                          <Divider orientation="vertical" />
                          <Select
                            defaultValue="m"
                            variant="plain"
                            onChange={(e: any, newValue: any) => {
                              setDelayTimeUnit(newValue);
                            }}
                            name="delay_time_unit"
                            slotProps={{
                              listbox: {
                                variant: 'outlined',
                              },
                            }}
                            sx={{ mr: -1, '&:hover': { bgcolor: 'transparent' } }}
                          >
                            <Option value="m">Minutes</Option>
                            <Option value="h">Hours</Option>
                            <Option value="d">Days</Option>
                          </Select>
                        </>
                      } />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }} error={phoneError}>
                      <FormLabel>Phone</FormLabel>
                      <Input name="recipient_phone" size="sm" placeholder="5555555555" required={true}  />
                      {phoneError && <FormHelperText>
                        <InfoOutlined />
                        Please use 10 digit format with no other characters
                      </FormHelperText>}
                    </FormControl>
                  </Stack>
                  <Stack spacing={2}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Address Line 1</FormLabel>
                      <Input name="recipient_address_1" size="sm" placeholder="Address" required={true} />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Address Line 2</FormLabel>
                      <Input
                        size="sm"
                        name="recipient_address_2"
                        // startDecorator={<EmailRoundedIcon />}
                        placeholder="Address Line 2"
                        sx={{ flexGrow: 1 }}
                      />
                    </FormControl>
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>City</FormLabel>
                      <Input name="recipient_city" size="sm" placeholder="City" required={true} />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>State</FormLabel>
                      <Input
                        size="sm"
                        name="recipient_state"
                        // startDecorator={<EmailRoundedIcon />}
                        placeholder="State"
                        sx={{ flexGrow: 1 }}
                        required={true}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Zip Code</FormLabel>
                      <Input
                        size="sm"
                        name="recipient_zip"
                        // startDecorator={<EmailRoundedIcon />}
                        placeholder="Zip Code"
                        sx={{ flexGrow: 1 }}
                        required={true}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>
              <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                  <Button size="sm" variant="outlined" color="neutral">
                    Cancel
                  </Button>
                  <Button type="submit" size="sm" variant="solid">
                    Create Shipment
                  </Button>
                </CardActions>
              </CardOverflow>
            </Card>

            {/* <Card>
              <Box sx={{ mb: 1 }}>
                <Typography level="title-md">Parcel Info</Typography>
              </Box>
              <Divider />
              <Stack
                direction="row"
                spacing={3}
                sx={{ display: { xs: 'flex' }, my: 1 }}
              >
                <Stack spacing={2} sx={{ flexGrow: 1, maxWidth: '100%' }}>
                  <Stack spacing={2}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Invoice Number</FormLabel>
                      <Input name="invoice_number" size="sm" placeholder="INV-XXXX" />
                    </FormControl>
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ flexGrow: 1, flexWrap: 'wrap' }}>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Length</FormLabel>
                      <Input name="p_length" size="sm" placeholder="Length" />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Width</FormLabel>
                      <Input
                        size="sm"
                        name="width"
                        // startDecorator={<EmailRoundedIcon />}
                        placeholder="Width"
                        sx={{ flexGrow: 1 }}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Height</FormLabel>
                      <Input
                        size="sm"
                        name="height"
                        // startDecorator={<EmailRoundedIcon />}
                        placeholder="Height"
                        sx={{ flexGrow: 1 }}
                      />
                    </FormControl>
                    <FormControl sx={{ flexGrow: 1 }}>
                      <FormLabel>Weight</FormLabel>
                      <Input
                        size="sm"
                        name="weight"
                        // startDecorator={<EmailRoundedIcon />}
                        placeholder="Weight"
                        sx={{ flexGrow: 1 }}
                      />
                    </FormControl>
                  </Stack>
                </Stack>
              </Stack>

            </Card> */}
          </Stack>
        </form>
      </>
    </MainTemplate>
  )
}

export default CreateOrder