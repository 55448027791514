import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

import MainTemplate from '../../../templates/main';
import OrderTable from '../../../components/ordertable';
import OrderList from '../../../components/orderlist';
import { useEffect, useState } from 'react';
import { Button } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import BulkMessageModalDialog from '../../../components/modal';
import React from 'react';


export default function DashboardPage({ sender }: { sender: boolean }) {
  const navigate = useNavigate();

  const [data, setData] = useState([])
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = React.useState<string[]>([]);

  useEffect(() => {
    fetch('https://hook.us1.make.com/c6huosw5n1fwoxqil5s487k985dfodn6').then(results => results.json())
      .then(data => {
        setData(data)
      })
  }, [])

  return (
    <MainTemplate page="Orders" activeSidebar='orders' sender={true}>
      <>
      <BulkMessageModalDialog open={open} setOpen={setOpen} invoices={selected} />
        <Box
          sx={{
            display: 'flex',
            mb: 1,
            gap: 1,
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'start', sm: 'center' },
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Typography level="h2" component="h1">
            Orders
          </Typography>
          <div style={{ display: 'flex', columnGap: '1rem' }}>
            <Button
              color="primary"
              size="sm"
              onClick={() => setOpen(true)}
            >
              Send Message
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={() => navigate('/sender/create-order')}
            >
              Create Order
            </Button>
          </div>
        </Box>
        <OrderTable rows={data} selected={selected} setSelected={setSelected} />
        <OrderList listItems={data} />
      </>
    </MainTemplate>
  );
}
