import { CssVarsProvider, CssBaseline, Box, Breadcrumbs, Typography, Link } from "@mui/joy";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { ReactNode } from "react";
import { ROUTES } from "../../constants";

export default function MainTemplate({ page, activeSidebar, sender, children }: { page: string, activeSidebar?: string, sender: boolean, children: ReactNode }) {
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: 'flex', height: '100dvh', overflowY: 'scroll' }}>
        <Header sender={sender} />
        {sender ? <Sidebar /> : <></>}
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: 'calc(12px + var(--Header-height))',
              sm: 'calc(12px + var(--Header-height))',
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
          }}
        >
          {sender && <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon fontSize="small" />}
              sx={{ pl: 0 }}
            >
              <HomeRoundedIcon />
              <Link
                underline="hover"
                color="neutral"
                href={ROUTES.dashboard}
                fontSize={12}
                fontWeight={500}
              >
                Dashboard
              </Link>
              <Typography color="primary" fontWeight={500} fontSize={12}>
                {page}
              </Typography>
            </Breadcrumbs>
          </Box>}
          {children}
        </Box>
      </Box>
    </CssVarsProvider>
  )
}