export function openSidebar() {
  if (typeof window !== 'undefined') {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
  }
}

export function closeSidebar() {
  if (typeof window !== 'undefined') {
    document.documentElement.style.removeProperty('--SideNavigation-slideIn');
    document.body.style.removeProperty('overflow');
  }
}

export function toggleSidebar() {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideNavigation-slideIn');
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
}

export const phoneNumberAutoFormat = (phoneNumber: string): string => {
  if (!phoneNumber) return ''
  const number = phoneNumber.trim().replace(/[^0-9]/g, "");

  if (number.length < 4) return number;
  if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
  if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
  return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
};

export const statusToText = (code: string) => {
  const status: Record<string, string> = {
    'in_transit': 'In Transit',
    'out_for_delivery': 'Out for Delivery',
    delivered: 'Delivered',
    Confirmed: 'Confirmed',
    'pre_transit': 'Awaiting Shipping',
    'failure': 'Failure',
    'return_to_sender': 'Return to Sender',
    unknown: 'Awaiting Shipping'
  }

  return status[code];
}
