import { Html5QrcodeScanType, Html5QrcodeScanner } from "html5-qrcode";
import { useCallback, useEffect } from "react";

const ScannerPage = () => {
  const onScanSuccess = useCallback((decodedText: string, decodedResult: any) => {
    // handle the scanned code as you like, for example:
    console.log(`Code matched = ${decodedText}`, decodedResult);
    if (decodedText.includes('/verify/INV-')) {
      window.location.href = decodedText;
    }
  }, []);

  const onScanFailure = useCallback((error: string) => {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
    console.warn(`Code scan error = ${error}`);
  }, []);

  useEffect(() => {
    let html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      { fps: 10, qrbox: { width: 250, height: 250 }, supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA], videoConstraints: { facingMode: { exact: "environment" } } },
      /* verbose= */ false);
    html5QrcodeScanner.render(onScanSuccess, onScanFailure);
  }, [onScanFailure, onScanSuccess]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
      <div id="reader" style={{ width: 300 }} />
    </div>
  )
}

export default ScannerPage