import * as React from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
// import Add from '@mui/icons-material/Add';
import Textarea from '@mui/joy/Textarea';

interface BulkMessageModalDialogProps {
  open: boolean;
  setOpen: (o: boolean) => void;
  invoices: any[]
}

export default function BulkMessageModalDialog({ open, setOpen, invoices = [] }: BulkMessageModalDialogProps) {

  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <DialogTitle>Send Bulk Message</DialogTitle>
          <DialogContent>Enter the SMS message to send</DialogContent>
          <form
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              const payload = {
                invoices: invoices,
                message: (event.target as any)['message'].value
              }
              fetch('https://hook.us1.make.com/3x423dqrphy9jesoonmdeo6tqd8q3exq', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
              }).then(() => {
                setOpen(false);
              });
            }}
          >
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Invoice Numbers</FormLabel>
                <div>{invoices.map(i => i).join(', ')}</div>
              </FormControl>
              <FormControl>
                <FormLabel>Message</FormLabel>
                <Textarea name="message" minRows={3} maxRows={4} required />
              </FormControl>
              <Button type="submit">Submit</Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}