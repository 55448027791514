import { Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { WebcamCapture } from "./camera";

export default function DriverVerificationPage() {
  const { invoice } = useParams();
  const [image, setImage] = useState('');

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const coords = position.coords;
        if (image) {
          fetch(`https://hook.us1.make.com/fnimgs4v15b7yqhblqixrs4v8bg0kxv1`, {
            method: 'POST',
            body: JSON.stringify({
              invoice_id: invoice,
              image: image,
              lat: coords.latitude,
              long: coords.longitude
            } as any),
            headers: {
              'Content-Type': 'application/json'
            }
          })
        }
      })
    }

  }, [invoice, image]);


  return (
    <Stack height="60%" justifyContent="center" alignItems="center" spacing={2} padding="18px">
      <CheckCircleIcon color="success" style={{ width: 200, height: 200 }} />
      <Typography textAlign="center" level="h2" color="success">Recipient notified!</Typography>
      <Typography textAlign="center" level="h4">Please take a photo of the package</Typography>
      {!image ? <WebcamCapture setImage={setImage} /> : <div style={{ height: '2rem' }}>Thank You!</div>}
    </Stack>
  )
}