import OrderStatus from '../../../components/orderstatus'
import MainTemplate from '../../../templates/main'



const OrderStatusPage = ({ sender }: { sender: boolean }) => {
  return (
    <MainTemplate page="Order Status" sender={sender}>
      <OrderStatus sender={sender} />
    </MainTemplate>
  ) 
}

export default OrderStatusPage